import React from "react"
import { ThemeProvider } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import LayoutDefault from "../layouts/default"
import ProjectsOverview from "../components/projects-overview"
import Footer from "../components/footer"

const ProjectsPage = () => {

  const data = useStaticQuery(graphql`
    query ProjectsQuery {
      prismicProjects {
        data {

          projects {
            project {
              document {
                uid
                data {
                  project_title {
                    text
                  }
                  teaser_image {
                    alt
                    large {
                      url
                    }
                    medium {
                      url
                    }
                  }
                  vimeo_video_id {
                    text
                  }
                  teaser_color
                  year {
                    text
                  }
                  category {
                    document {
                      data {
                        category_title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }

        }
      }
    }  
  `)

  const content = data.prismicProjects.data

  return (
    <ThemeProvider theme={{ mode: "light" }}>
      <LayoutDefault>
        <ProjectsOverview input={ content } />
        <Footer />
      </LayoutDefault>
    </ThemeProvider>
  )
}

export default ProjectsPage
