import React from "react"
import ProjectTeaserOverview from "../project-teaser-overview"
import Styled from "./styled"

const ProjectsOverview = props => {

  const projects = props.input.projects && props.input.projects.map((node, index) => {
    return (
      <ProjectTeaserOverview key={ index } input={ node } />
    )
  })

  return (
    <Styled.Grid>
      { projects }
    </Styled.Grid>
  )
}

export default ProjectsOverview