import styled, { keyframes } from "styled-components"
import themeSettings from "../../styles/theme"

const Teaser = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const TeaserInfo = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: ${themeSettings.margins.base};

  & > h3 {
    font-size: ${themeSettings.fontSizes.small};

    /* Holds Category */
    &:nth-child(2) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      @media screen and (max-width: 414px) {
        display: none;
      }
    }
    /* Holds Year */
    &:nth-child(3) {
      /* letter-spacing: 0.15em; */
      letter-spacing: 2.6px;
      transform: translateX(2.6px);

      @media screen and (max-width: 414px) {
        display: none;
      }
    }
  }
`

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
`

const Dot = styled.div`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 16px);
  width: 32px;
  height: 32px;
  opacity: 0;
  border-radius: 50%;
  z-index: 2;
  animation: 1.4s ${pulse} infinite ease-out;
`

const Media = styled.div`
  position: relative;
  width: 100%;
  &::after {
    content: "";
    display: block;
    padding-bottom: 56.25%;
  }
`

const MediaInner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > picture {
    /* width: 100%;
    height: 100%; */
    & > img {
      width: 100%;
      /* height: 100%; */
      max-height: 100%;
      object-fit: cover;
      vertical-align: bottom;

      @media screen and (max-width: 414px) {
        height: 30vw;
      }
    }
  }
`

const Video = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  /* height: calc(100% - 20px - ${themeSettings.margins.base}); */
  height: 100%;
  /* min-height: 254px; */
  background-color: ${themeSettings.colors.light};
  overflow: hidden;
  pointer-events: none;
  /* @media screen and (max-width: 414px) {
    min-height: 117px;
  } */
`

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;
  vertical-align: bottom;
  background-color: ${themeSettings.colors.grey};

  iframe {
    width: 100%;
    height: 56.25%;
    min-height: 110%;
    min-width: 177.77%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export default {
  Teaser,
  TeaserInfo,
  Dot,
  Media,
  MediaInner,
  Video,
  VideoWrapper,
}
