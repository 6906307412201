import styled from "styled-components"
import themeSettings from "../../styles/theme"

const Grid = styled.section`
  box-sizing: border-box;
  width: 100%;
  margin: ${themeSettings.navHeight}px 0
    calc(${themeSettings.margins.large} * 2) 0;
  padding: 0 ${themeSettings.margins.page};

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: calc(${themeSettings.margins.page} * 3);
  grid-row-gap: calc(${themeSettings.margins.page} * 2);

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 414px) {
    margin: ${themeSettings.mobileNavHeight}px 0
      calc(${themeSettings.margins.large} * 2) 0;
    grid-column-gap: ${themeSettings.mobileMargins.page};
    grid-row-gap: ${themeSettings.margins.page};
    padding: 0 ${themeSettings.mobileMargins.page};
  }
`

export default {
  Grid,
}
