import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Styled from "./styled"

const ProjectTeaserOverview = props => {
  const [dotVisibility, setDotVisibility] = useState(false)

  const [vimeoAccepted, setVimeoAccepted] = useState(false)

  useEffect(() => {
    if (window.localStorage.getItem("fb-cookies-accepted") === "true") {
      setVimeoAccepted(true)
    } else {
      setVimeoAccepted(false)
    }
  }, [])

  const dotToggle = () => {
    setDotVisibility(!dotVisibility)
  }

  const itemData = props.input.project.document["0"].data

  const delayLink = (event, linkTarget) => {
    if (window.innerWidth <= 1025) {
      event.preventDefault()
      setTimeout(() => {
        window.location.href = `/projects/${linkTarget}`
      }, 300)
    } else {
      return
    }
  }

  return (
    <Link
      to={`/projects/${props.input.project.document["0"].uid}`}
      onClick={event => delayLink(event, props.input.project.document["0"].uid)}
    >
      <Styled.Teaser onMouseOver={dotToggle} onMouseOut={dotToggle}>
        <Styled.Dot
          style={{
            backgroundColor: itemData.teaser_color,
            opacity: dotVisibility ? 1 : 0,
          }}
        />
        {itemData.vimeo_video_id?.text?.length >= 1 ? (
          <Styled.Media>
            <Styled.MediaInner>
              <Styled.Video>
                <Styled.VideoWrapper>
                  {vimeoAccepted && (
                    <iframe
                      title="Vimeo Player"
                      src={`https://player.vimeo.com/video/${itemData.vimeo_video_id.text}?background=1&autoplay=1&autopause=0&loop=1&byline=0&title=0&muted=1&portrait=0`}
                      width="1920"
                      height="1080"
                      frameBorder="0"
                      allow="autoplay"
                    />
                  )}
                </Styled.VideoWrapper>
              </Styled.Video>
            </Styled.MediaInner>
          </Styled.Media>
        ) : (
          <Styled.Media>
            <Styled.MediaInner>
              <picture>
                <img
                  alt={"Studio Fabio Biesel " + itemData.teaser_image.alt}
                  src={itemData.teaser_image.large.url}
                />
              </picture>
            </Styled.MediaInner>
          </Styled.Media>
        )}
        <Styled.TeaserInfo>
          <h3>{itemData.project_title.text}</h3>
          <h3>{itemData.category.document["0"].data.category_title.text}</h3>
          <h3>{itemData.year.text}</h3>
        </Styled.TeaserInfo>
      </Styled.Teaser>
    </Link>
  )
}

export default ProjectTeaserOverview
